import { openModal } from "@/store/redux/actions/GlobalModalAction";
import { colors, createStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/JobID v-02.svg";
interface TopBarProps {
  className?: string;
  onOpenNavBarMobile?: () => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      boxShadow: "none",
    },
    flexGrow: {
      flexGrow: 1,
    },
    notificationsButton: {
      marginLeft: theme.spacing(1),
    },
    notificationsBadge: {
      backgroundColor: colors.orange[600],
    },
    logo: {},
    logoSvg: {
      height: "32px",
      fill: "#323232",
      float: "left",
    },
    logoTitle: {
      padding: "9px 10px",
      float: "left",
      background: "#323232",
      borderRadius: "4px",
      color: "#f0f0f0",
      fontWeight: 900,
      fontSize: "12px",
      margin: "1px 0 0 8px",
    },
    pointer: {
      cursor: "pointer",
    },
  })
);

const TopBar: React.FunctionComponent<TopBarProps> = (props: TopBarProps) => {
  const { onOpenNavBarMobile, className, ...rest } = props;
  const classes = useStyles();
  const notificationsRef = useRef(null);
  const [openNotifications, setOpenNotifications] = useState(false);
  // @ts-ignore
  const { notification } = useSelector((state: any) => state);

  const dispatch = useDispatch();

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const handleRedirectHome = () => {
    let payload = {
      title: "Về trang chủ?",
      content: (
        <>
          Chọn <strong>OK</strong> để quay trở về trang chủ.
        </>
      ),
      action: () => {
        window.location.href = process.env.REACT_APP_BASE_URL as string;
      },
    };
    dispatch(openModal(payload));
  };

  return (
    <AppBar {...rest} className={classes.root} color="primary">
      <Toolbar>
        <Link to={"/tong-quan"} className={classes.logo}>
          <img className={classes.logoSvg} src={logo} alt="logo" width={100} height={40} />
          <div className={classes.logoTitle}>NHÀ TUYỂN DỤNG</div>
        </Link>

        <div className={classes.flexGrow} />

        {/*<IconButton*/}
        {/*  className={classes.notificationsButton}*/}
        {/*  color="inherit"*/}
        {/*  onClick={handleNotificationsOpen}*/}
        {/*  ref={notificationsRef}*/}
        {/*>*/}
        {/*  <Badge badgeContent={notification.unread_length} color="error">*/}
        {/*    <NotificationsIcon/>*/}
        {/*  </Badge>*/}
        {/*</IconButton>*/}
        <IconButton color={"inherit"} onClick={() => handleRedirectHome()}>
          <HomeOutlinedIcon className={classes.pointer} />
        </IconButton>
        <Hidden mdUp>
          <IconButton color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>

      {/*<NotificationsPopover*/}
      {/*  anchorEl={notificationsRef.current}*/}
      {/*  onClose={handleNotificationsClose}*/}
      {/*  open={openNotifications}*/}
      {/*/>*/}
    </AppBar>
  );
};

export default TopBar;
