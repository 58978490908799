import UserType from "@/store/redux/types/UserType";
import { takeLatest, put, all, fork } from "redux-saga/effects";
import { axios } from "@/utils/axiosInstance";
import { loadingAppSuccess } from "@/store/redux/actions/LoadingAppAction";
import { fetchUserFailed, fetchUserSucceed } from "@/store/redux/actions/UserAction";
import Cookies from "universal-cookie";

function* fetchUser() {
  try {
    const response = yield axios({
      url: `${process.env.REACT_APP_API_URL}/auth/employer/login`,
      method: "post",
    });
    // let response = {
    //   data: {
    //     employer: {
    //       first_name: "Bui Pham Thanh",
    //       last_name: "Nguyen",
    //       full_name: "Bui Pham Thanh Nguyen",
    //       birth_day: null,
    //       avatar: "https://lh3.googleusercontent.com/a/ACg8ocKtM3rLmy1MWcf3zn7AxFB-1Ua5I5d-vjHnwxHmXZor57Eutg=s96-c",
    //       gender: "",
    //       spam: 0,
    //       _id: "66ecdb6f10c541f530dc2013",
    //       email: "nguyen.bpt@ikigroup.vn",
    //       created_at: "2024-09-20T02:18:24.882Z",
    //       updated_at: "2024-09-25T09:14:29.192Z",
    //       __v: 0,
    //       accessToken:
    //         "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImZpcnN0X25hbWUiOiJCdWkgUGhhbSBUaGFuaCIsImxhc3RfbmFtZSI6Ik5ndXllbiIsImZ1bGxfbmFtZSI6IkJ1aSBQaGFtIFRoYW5oIE5ndXllbiIsImJpcnRoX2RheSI6bnVsbCwiYXZhdGFyIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUNnOG9jS3RNM3JMbXkxTVdjZjN6bjdBeEZCLTFVYTVJNWQtdmpIbnd4SG1YWm9yNTdFdXRnPXM5Ni1jIiwiZ2VuZGVyIjoiIiwic3BhbSI6MCwiX2lkIjoiNjZlY2RiNmYxMGM1NDFmNTMwZGMyMDEzIiwiZW1haWwiOiJuZ3V5ZW4uYnB0QGlraWdyb3VwLnZuIiwiY3JlYXRlZF9hdCI6IjIwMjQtMDktMjBUMDI6MTg6MjQuODgyWiIsInVwZGF0ZWRfYXQiOiIyMDI0LTA5LTI1VDA5OjA0OjUzLjE1NFoiLCJfX3YiOjAsImFjY2Vzc1Rva2VuIjoiIiwicmVmcmVzaFRva2VuIjoiIn0sImlhdCI6MTcyNzI1NTY2OSwiZXhwIjoxNzI3MjU5MjY5fQ.1K5vRVqcHHc2l8dxGMnahsa6aQ-I18Km-gGfYRIYiBI",
    //       refreshToken:
    //         "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImZpcnN0X25hbWUiOiJCdWkgUGhhbSBUaGFuaCIsImxhc3RfbmFtZSI6Ik5ndXllbiIsImZ1bGxfbmFtZSI6IkJ1aSBQaGFtIFRoYW5oIE5ndXllbiIsImJpcnRoX2RheSI6bnVsbCwiYXZhdGFyIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUNnOG9jS3RNM3JMbXkxTVdjZjN6bjdBeEZCLTFVYTVJNWQtdmpIbnd4SG1YWm9yNTdFdXRnPXM5Ni1jIiwiZ2VuZGVyIjoiIiwic3BhbSI6MCwiX2lkIjoiNjZlY2RiNmYxMGM1NDFmNTMwZGMyMDEzIiwiZW1haWwiOiJuZ3V5ZW4uYnB0QGlraWdyb3VwLnZuIiwiY3JlYXRlZF9hdCI6IjIwMjQtMDktMjBUMDI6MTg6MjQuODgyWiIsInVwZGF0ZWRfYXQiOiIyMDI0LTA5LTI1VDA5OjA0OjUzLjE1NFoiLCJfX3YiOjAsImFjY2Vzc1Rva2VuIjoiIiwicmVmcmVzaFRva2VuIjoiIn0sImlhdCI6MTcyNzI1NTY2OSwiZXhwIjoxNzMyNDM5NjY5fQ.etBPrCsn9HnZ6-mFJaQLdEQWdn19B03dRrgXtGTT9Vk",
    //     },
    //   },
    // };

    if (response.data.employer) {
      let payload = {
        user: response.data.employer,
      };
      yield put(fetchUserSucceed(payload));
    } else {
      yield put(fetchUserFailed());
    }
  } catch (e) {
    yield put(fetchUserFailed());
  }
}

function* watchFetchUser() {
  yield takeLatest(UserType.FETCH_USER, fetchUser);
}

function* UserSaga() {
  yield all([fork(watchFetchUser)]);
}

export default UserSaga;
